import moment, { Moment } from 'moment-timezone';
import {
  ACTION,
  AGGREGATION_TYPE,
  DARK_THEME_COLOR,
  DATE_DIFF_TYPE,
  DATE_FILTER_CRITERIA,
  DATE_TIME_FORMAT,
  ENABLE_DATA_SUBSTITUTION_TYPE,
  LOCALSTORAGE_KEY,
  LOCAL_STORAGE_KEYS,
  PHONENUMBER_REGEX,
  SYSTEM_CONFIG_KEYS,
} from '.';
import json2csvExport from 'json-to-csv-export';
import { ZoomPluginOptions } from 'chartjs-plugin-zoom/types/options';
import { decodedJwt } from '../services/base-service';
import { RawRule } from '@casl/ability'
import { isValidPhoneNumber } from 'libphonenumber-js';
import { ISelectOption } from '../views/nem12-report/nem12-report.model';
import { MeterEventData } from '../views/dashboard/dashboard.model';
import ExcelJS, { TableColumnProperties } from 'exceljs';
import FileSaver from 'file-saver';


const momentFields = ['modified_on', 'timestamp', 'event_timestamp', 'timestamp2', 'ts', 'active_from_date'];
const isMomentField = (field: string) => momentFields.includes(field);

export const getEnvVariables = () => {
  const {
    REACT_APP_CLIENT_ID,
    REACT_APP_AUTHORITY,
    REACT_APP_KNOWNAUTHORITIES,
    REACT_APP_PASSWORDRESETURL,
  } = process.env;

  return {
    REACT_APP_CLIENT_ID,
    REACT_APP_AUTHORITY,
    REACT_APP_KNOWNAUTHORITIES,
    REACT_APP_PASSWORDRESETURL,
  };
};

export const viewMode = (mode: string) => mode.toUpperCase() === ACTION.VIEW;

const getPreviuousMonthStartDate = (monthBefore: number) => {
  return moment()
    .subtract(monthBefore, 'months')
    .startOf('month')
    .format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
};

const getPreviuousMonthEndDate = (monthBefore: number) => {
  return moment().startOf('month').format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
};

export const validateDateRange = (from_date: string, to_date: string) => {
  if (from_date && to_date) {
    const fromDate = moment(from_date);
    const toDate = moment(to_date);

    if (toDate.isBefore(fromDate)) {
      return "Please select a valid date range.";
    }
  }
  return '';
};

export const getDatesAsPerSelectedCriteria = (
  filterCriteria: string,
  from_date?: Moment | string,
  to_date?: Moment | string,
) => {
  const today = new Date();
  let fDate;
  let tDate;
  tDate = moment(today).format(DATE_TIME_FORMAT.REQUEST_DATE);
  if (filterCriteria == DATE_FILTER_CRITERIA.LAST_MONTH) {
    fDate = getPreviuousMonthStartDate(1);
    tDate = getPreviuousMonthEndDate(1);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.LAST_3_MONTHS) {
    fDate = getPreviuousMonthStartDate(2);
    tDate = new Date().setDate(today.getDate());
  } else if (filterCriteria == DATE_FILTER_CRITERIA.LAST_30_DAYS) {
    fDate = new Date().setDate(today.getDate() - 30);
    tDate = new Date().setDate(today.getDate() + 1);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.CURRENT_MONTH) {
    fDate = moment().startOf('month').format(DATE_TIME_FORMAT.REQUEST_DATE);
    tDate = new Date().setDate(today.getDate() + 1);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.CURRENT_YEAR) {
    fDate = moment().startOf('year').format(DATE_TIME_FORMAT.REQUEST_DATE);
    tDate = new Date().setDate(today.getDate() + 1);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.PREVIOUS_YEAR) {
    fDate = moment().subtract(1, 'year').startOf('year').format(DATE_TIME_FORMAT.REQUEST_DATE);
    tDate = moment().startOf('year').format(DATE_TIME_FORMAT.REQUEST_DATE);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.CUSTOM) {
    fDate = from_date;
    tDate = to_date;
  } else if (filterCriteria == DATE_FILTER_CRITERIA.TODAY) {
    fDate = new Date().setDate(today.getDate());
    tDate = new Date().setDate(today.getDate() + 1);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.YESTERDAY) {
    fDate = new Date().setDate(today.getDate() - 1);
    tDate = new Date().setDate(today.getDate());
  } else if (filterCriteria == DATE_FILTER_CRITERIA.LAST_WEEK) {
    fDate = new Date().setDate(today.getDate() - 7);
    tDate = new Date().setDate(today.getDate());
  } else {
    tDate = '';
  }
  return { fDate, tDate };
};

export const getDatesForComparison = (
  filterCriteria: string,
  from_date?: Moment | string,
  to_date?: Moment | string,
) => {
  const today = new Date();
  let fDate;
  let tDate;
  tDate = moment(today).format(DATE_TIME_FORMAT.REQUEST_DATE);
  if (filterCriteria == DATE_FILTER_CRITERIA.LAST_MONTH) {
    fDate = getPreviuousMonthStartDate(2);
    tDate = getPreviuousMonthEndDate(2);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.LAST_3_MONTHS) {
    fDate = getPreviuousMonthStartDate(5);
    tDate = getPreviuousMonthEndDate(3);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.LAST_30_DAYS) {
    fDate = new Date().setDate(today.getDate() - 60);
    tDate = new Date().setDate(today.getDate() - 30);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.CURRENT_MONTH) {
    fDate = getPreviuousMonthStartDate(1);
    tDate = getPreviuousMonthEndDate(1);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.CURRENT_YEAR) {
    fDate = moment().subtract(1, 'year').startOf('year').format(DATE_TIME_FORMAT.REQUEST_DATE);
    tDate = moment().subtract(1, 'year').endOf('year').format(DATE_TIME_FORMAT.REQUEST_DATE);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.PREVIOUS_YEAR) {
    fDate = moment().subtract(2, 'year').startOf('year').format(DATE_TIME_FORMAT.REQUEST_DATE);
    tDate = moment().subtract(2, 'year').endOf('year').format(DATE_TIME_FORMAT.REQUEST_DATE);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.CUSTOM) {
    fDate = from_date;
    tDate = to_date;
  } else if (filterCriteria == DATE_FILTER_CRITERIA.TODAY) {
    fDate = new Date().setDate(today.getDate() - 1);
    tDate = new Date().setDate(today.getDate());
  } else if (filterCriteria == DATE_FILTER_CRITERIA.LAST_WEEK) {
    fDate = new Date().setDate(today.getDate() - 14);
    tDate = new Date().setDate(today.getDate() - 7);
  } else if (filterCriteria == DATE_FILTER_CRITERIA.YESTERDAY) {
    fDate = new Date().setDate(today.getDate() - 2);
    tDate = new Date().setDate(today.getDate() - 1);
  } else {
    tDate = '';
  }

  let DATE_FORMAT = DATE_TIME_FORMAT.REQUEST_DATE;

  if (filterCriteria == DATE_FILTER_CRITERIA.CUSTOM) {
    DATE_FORMAT = DATE_TIME_FORMAT.REQUEST_DATE_TIME;
  }

  let fromDate = fDate ? moment(fDate).format(DATE_FORMAT) : '';

  let toDate = tDate ? moment(tDate).format(DATE_FORMAT) : '';
  return { fromDate, toDate };
};

export const getDateBasedOnCriteria = (
  filterCriteria: string,
  from_date?: Moment | string,
  to_date?: Moment | string,
  timezone?: string,
) => {
  let DATE_FORMAT = DATE_TIME_FORMAT.REQUEST_DATE;

  if (filterCriteria == DATE_FILTER_CRITERIA.CUSTOM) {
    DATE_FORMAT = DATE_TIME_FORMAT.REQUEST_DATE_TIME;
  }

  const _timezone = timezone;
  const filteredDates = getDatesAsPerSelectedCriteria(filterCriteria, from_date, to_date);
  let fDate = filteredDates.fDate;
  let tDate = filteredDates.tDate;

  let fromDate = fDate ? moment(fDate).format(DATE_FORMAT) : '';

  tDate = tDate ? moment(tDate).format(DATE_FORMAT) : '';

  if (_timezone) {
    if (fromDate) {
      fromDate = moment.tz(fromDate, _timezone).utc().format(DATE_FORMAT);
    }

    if (tDate) {
      tDate = moment.tz(tDate, _timezone).utc().format(DATE_FORMAT);
    }
  } else {
    if (fromDate) {
      const utcTimestamp = moment.utc(fromDate);
      fromDate = utcTimestamp.format(DATE_FORMAT);
    }

    if (tDate) {
      const utcTimestamp = moment.utc(tDate);
      tDate = utcTimestamp.format(DATE_FORMAT);
    }
  }

  return {
    fromDate,
    tDate,
  };
};

export const getTimezoneDateTime = (fromDate?: string, toDate?: string, timezone?: string) => {
  let _fromDate = fromDate;
  let _toDate = toDate;
  if (timezone) {
    if (fromDate) {
      _fromDate = moment.tz(fromDate, timezone).utc().format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    }

    if (toDate) {
      _toDate = moment.tz(toDate, timezone).utc().format(DATE_TIME_FORMAT.REQUEST_DATE_TIME);
    }
  }

  return {
    fromDate: _fromDate,
    toDate: _toDate,
  };
};

export const convertDateTimeInLocalFormat = (timestamp: Moment | string, timezone?: string) => {
  //const _timezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const _timezone = timezone;
  const utcTimestamp = moment.utc(timestamp);
  if (_timezone) {
    return utcTimestamp.clone().tz(_timezone).format(DATE_TIME_FORMAT.DATE_TIME);
  }
  return utcTimestamp.format(DATE_TIME_FORMAT.DATE_TIME);
};

export const convertDateTimeInLocalFormat2 = (timestamp: Moment, timezone?: string) => {
  const _timezone = timezone;
  const utcTimestamp = moment.utc(timestamp);
  if (_timezone) {
    return utcTimestamp.clone().tz(_timezone).format(DATE_TIME_FORMAT.DATE_TIME_RAW);
  }
  return utcTimestamp.format(DATE_TIME_FORMAT.DATE_TIME_RAW);
};

export const convertDateTimeInLocalFormatTempMethod = (timestamp: string, format: string) => {
  let date = timestamp.replace("T", " ");
  date = date.replace("Z", "");
  return moment(date, "YYYY-MM-DD HH:mm:ss.SSS").format(format);
};

export const exportData = async (getDataForExport: any, filename: string, timezone?: string) => {
  if (getDataForExport) {
    const exportObject = await getDataForExport();
    if (exportObject) {
      const { data: exportData, columns: exportColumn } = exportObject;
      if (exportData.length && exportColumn.length) {
        const _fileName = filename ? `${filename}.csv` : 'AI-Data.csv';
        const _exportColumn = exportColumn.filter(
          (column: any) => column.Header.toLowerCase() !== 'action',
        );
        const jsonToCsv = exportData.map((item: any) => {
          return mapHeader(item, _exportColumn as [], timezone);
        });
        json2csvExport(jsonToCsv, _fileName);
      }
    }
  }
  return null;
};

export const exportDataUsingWorkbook = async (export_columns: TableColumnProperties[], export_data: any, filename: string) => {

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  worksheet.addTable({
    name: 'MyTable',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleLight1",
      showRowStripes: false,
    },
    columns: export_columns,
    rows: export_data
  });

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    FileSaver.saveAs(url, `${filename}`);
  });

}

const mapHeader = (item: any, columns: [], timezone?: string) => {
  const returnObject = {} as any;
  columns.forEach((column: any) => {
    const columnAccessor = column.accessor ?? column.id;
    if (isMomentField(columnAccessor)) {
      returnObject[column.Header] = item[columnAccessor] ? convertDateTimeInLocalFormat(item[columnAccessor], timezone) : '';
    } else {
      const getValue = item[columnAccessor];
      var value = columnAccessor.split('.').reduce((a: any, b: any) => {
        return a ? a[b] : undefined;
      }, item);
      returnObject[column.Header] = value !== undefined ? value : getValue;
    }
  });
  return returnObject;
};

export const getRoundOffValues = (value: number | undefined, _decimalValue?: number | null) => {
  const _value = value ?? 0;
  if (!_decimalValue) {
    const decimalValue = getLocalStorageSystemConfig(SYSTEM_CONFIG_KEYS.DECIMAL_VALUE);
    const parsedDecimalValue = Number(decimalValue);

    if (!isNaN(parsedDecimalValue) && parsedDecimalValue >= 0 && parsedDecimalValue <= 100) {
      const roundedValue = Math.round(_value * Math.pow(10, parsedDecimalValue)) / Math.pow(10, parsedDecimalValue);
      return Number(roundedValue.toFixed(parsedDecimalValue));
    }
  } else {
    return Number(_value.toFixed(_decimalValue));
  }

  return _value;
};

export const getLocalStorageSystemConfig = (key: string) => {
  const systemConfig = localStorage.getItem(LOCAL_STORAGE_KEYS.SYSTEM_CONFIG);
  if (systemConfig) return JSON.parse(systemConfig)[key];
  return null;
};

export const getDateDifference = (destination: string, fromDate?: string, toDate?: string) => {
  if (fromDate && toDate) {
    switch (destination) {
      case DATE_DIFF_TYPE.MILLI_SECONDS:
        return moment(toDate).diff(moment(fromDate), 'milliseconds');
      case DATE_DIFF_TYPE.SECONDS:
        return moment(toDate).diff(moment(fromDate), 'seconds');
      case DATE_DIFF_TYPE.MINUTES:
        return moment(toDate).diff(moment(fromDate), 'minutes');
      case DATE_DIFF_TYPE.HOURS:
        return moment(toDate).diff(moment(fromDate), 'hours');
      case DATE_DIFF_TYPE.DAY:
        return moment(toDate).diff(moment(fromDate), 'days');
      case DATE_DIFF_TYPE.MONTH:
        return moment(toDate).diff(moment(fromDate), 'months');
      case DATE_DIFF_TYPE.YEAR:
        return moment(toDate).diff(moment(fromDate), 'years');
    }
  }
  return undefined;
};

export const removeChannelTypeFromChannel = (item: string) => {
  return item.replace(/ *\([^)]*\) */g, '');
};

export const splitChannelTypeFromChannel = (
  specificOptionList: string[],
  raw_meter_channel_name: string,
) => {
  const splittedChannels: string[] = raw_meter_channel_name
    ? raw_meter_channel_name.split(',')
    : [];
  const displayedChannel: string[] = [];

  if (splittedChannels && splittedChannels.length) {
    splittedChannels.forEach((option) => {
      const filteredUOMChannel = specificOptionList.find(
        (item) => removeChannelTypeFromChannel(item).toLowerCase() === option.toLowerCase(),
      );
      if (filteredUOMChannel) {
        displayedChannel.push(filteredUOMChannel);
      }
    });
  }
  const text = displayedChannel.join(',');
  return text;
};

export const isOnlyNEM12Permission = () => {
  const abilities = localStorage.getItem(LOCALSTORAGE_KEY.ABILITIES);
  const Abilities = abilities ? JSON.parse(abilities) : [];
  if (Abilities.length === 1) {
    return Abilities.find((item: { action: string }) => item.action === 'NEM12View');
  }
  return false;
};

export const REDIRECT_ERROR_CODES = {
  FORGOT_PASSWORD: 'AADB2C90118',
  LOGIN: 'AADB2C90091',
};

export const devBlobURL = process.env.REACT_APP_NEM12_CSV_BLOB_URL;

export const DELAYED_API_SECONDS = 20 * 1000;

export const DURABLE_FUNCTION_RUNTIME_STATUS = {
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  RUNNING: 'Running',
};

export const prepareBillingURL = (sessionId: string) => {
  return `${devBlobURL}/${sessionId}/${sessionId}-Billing.json`;
};

export const prepareSummaryURL = (sessionId: string) => {
  return `${devBlobURL}/${sessionId}/${sessionId}-Summary.json`;
};

//export const NEM12DurableFnURL = 'https://dev-ai-meter-data.azurewebsites.net/api/nem12-durable';
export const NEM12DurableFnURL = `${process.env.REACT_APP_API_URL}/meter-data/nem12-durable`;

export const loggedUserIsAdmin = () => {
  const decodeToken = decodedJwt();
  if (decodeToken && decodeToken.payload && decodeToken.payload.userData) {
    const userData = JSON.parse(decodeToken.payload.userData);
    return userData.is_admin ?? false;
  }
  return false;
};

export const getDefaultChartOption = (maxY: number, maxX: number, minX?: number) => {
  return {
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontFamily: 'Nunito Sans, sans-sarif',
        fontColor: '#8898aa',
      },
    },
    plugins: {
      zoom: {
        limits: {
          y: {
            min: 0,
            max: maxY,
            minRange: 1,
          },
          y1: {
            min: minX,
            max: maxX,
            minRange: 1,
          }
        },
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl',
        },
        zoom: {
          wheel: { enabled: true, modifierKey: 'alt' },
          drag: { enabled: true },
          pinch: { enabled: true },
          mode: 'x',
        },
      } as ZoomPluginOptions,
      crosshair: false
    },
    scales: {
      y: {
        beginAtZero: true,
      }
    },

  };
};

export const getSelect2CSS = (customizer?: boolean) => {
  let isDarkMode = false;

  let menuList = (base: any) => ({
    ...base,
  });
  let control = (base: any, state: { isFocused: any }) => ({
    ...base,
    whiteSpace: 'nowrap', // Added for Meter data correction selection dropdown(Quality flag,Reason code and Method flags)
  });

  let singleValue = (base: any) => ({
    ...base,
  });

  let option = (base: any, state: any) => ({
    ...base,
  });

  let input = (base: any, state: any) => ({
    ...base,
  });

  let multiValue = (base: any) => ({
    ...base
  });
  let multiValueLabel = (provided: any) => ({
    ...provided,
  });

  if (customizer) {
    isDarkMode = customizer;
    if (isDarkMode) {
      menuList = (base: any) => ({
        ...base,
        background: DARK_THEME_COLOR.BG,
        color: DARK_THEME_COLOR.CO,
      });
      control = (base: any, state: { isFocused: any }) => ({
        ...base,
        background: DARK_THEME_COLOR.CONTROL_BG,
        borderColor: DARK_THEME_COLOR.CONTROL_BORDER_COLOR,
        color: DARK_THEME_COLOR.CO,
      });
      singleValue = (base: any) => ({
        ...base,
        color: DARK_THEME_COLOR.CO,
      });

      option = (base: any, state: any) => ({
        ...base,
        background: state.isFocused && !state.isSelected ? DARK_THEME_COLOR.OPTION_FOCUS_COLOR : base.background,
      });

      input = (base: any,) => ({
        ...base,
        color: DARK_THEME_COLOR.CO,
      });

      multiValue = (base: any) => ({
        ...base,
        backgroundColor: "#3699ff",
        color: "#fff"
      });

      multiValueLabel = (provided: any) => ({
        ...provided,
        color: "#fff",
      });
    }
  }

  return {
    control,
    menuList,
    singleValue,
    option,
    input,
    multiValue,
    multiValueLabel,
    clearIndicator: (base: any, state: any) => ({
      ...base,
      cursor: 'pointer',
    }),
  };
};

export const getTariffColor = (tariffDetails: any) => {
  let returnColor;
  if (tariffDetails && tariffDetails.tariff_color) {
    returnColor = tariffDetails.tariff_color;
  }
  return returnColor
}

export const getRandomOrderString = (str: string) => {
  const a = str.split("");
  const n = a.length;

  for (let i = n - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const tmp = a[i];
    a[i] = a[j];
    a[j] = tmp;
  }

  return a.join("");
}

export const getPermissions = (moduleName: string, abilities: RawRule[]) => {
  if (moduleName) {
    let permissionType: string[] = ['Add', 'Edit', 'Delete', 'View'];
    const permissions: any = {};
    if (moduleName === "UnprovisionedMeter") {
      const permission = abilities?.find((ability) => ability.action === (moduleName + permissionType[3]));
      if (permission) {
        permissions[`${permissionType[3]}Permission`] = permission;
        permissions[`${permissionType[2]}Permission`] = permission;
      }
      return permissions;
    }

    for (const type of permissionType) {
      const permission = abilities?.find((ability) => ability.action === (moduleName + type));
      if (permission)
        permissions[`${type}Permission`] = permission;
    }
    return permissions;
  }
  else return {}
}

export const HEXtoHSL = (hex: string) => {
  let result: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);

  r /= 255, g /= 255, b /= 255;
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let l = (max + min) / 2;

  l = l * 100;
  l = Math.round(l);

  return l;
}


export const getChartDataAccordingToRange = (from_date: string, to_date: string, data: any, interval: string, channel_type: string, isDelta: boolean): any => {

  let isCumulative = channel_type === AGGREGATION_TYPE.CUMULATIVE;
  let isInstantaneous = channel_type === AGGREGATION_TYPE.INSTANTANEOUS;

  for (let i = 0; i < data.length; i++) {
    data[i].timestamp = moment((data[i].timestamp.replace("T", " ")).replace("Z", ""), "YYYY-MM-DD HH:mm:ss");
  }

  let f_date: any, t_date: any;
  f_date = from_date.replace("T", " ");
  f_date = f_date.replace("Z", "");
  f_date = moment(f_date, "YYYY-MM-DD HH:mm:ss");

  t_date = to_date.replace("T", " ");
  t_date = t_date.replace("Z", "");
  t_date = moment(t_date, "YYYY-MM-DD HH:mm:ss");

  let diffYear = moment(t_date).diff(moment(f_date), 'years');
  let diffMonth = moment(t_date).diff(moment(f_date), 'months');
  let diffDays = moment(t_date).diff(moment(f_date), 'days');
  let diffHours = moment(t_date).diff(moment(f_date), 'hours');

  let isFromInterval = (interval === "" || interval === "1 min" || interval === "5 min" || interval === "10 min" || interval === "15 min" || interval === "30 min" || interval === "1 hour" || interval === "6 hour")

  if (diffYear && isFromInterval) {
    return seggregateDataInYears(f_date, t_date, data, isCumulative, isInstantaneous, isDelta);
  }
  else if (diffMonth && diffDays > 30 && isFromInterval) {
    return seggregateInMonths(f_date, t_date, data, isCumulative, isInstantaneous, isDelta);
  }
  else if (diffDays && diffHours > 24 && isFromInterval) {
    return seggregateInDays(f_date, t_date, data, isCumulative, isInstantaneous, isDelta);
  }
  else if (diffYear <= 0 && diffMonth <= 0 && diffHours <= 24 && isFromInterval) {
    return seggregateInTimestamps(data, isDelta);
  }
  else {
    if (interval === "1 day") {
      return seggregateSpecialCase(f_date, t_date, data, isCumulative, isInstantaneous, isDelta, interval)
    }
    return seggregateInTimestamps(data, isDelta);
  }
};

const seggregateSpecialCase = (f_date: string, t_date: string, data: any, isCumulative: boolean, isInstantaneous: boolean, isDelta: boolean, interval: string) => {
  let diffYear = moment(t_date).diff(moment(f_date), 'years');
  let diffMonth = moment(t_date).diff(moment(f_date), 'months');
  let diffDays = moment(t_date).diff(moment(f_date), 'days');
  let diffHours = moment(t_date).diff(moment(f_date), 'hours');

  if (diffYear) {
    return seggregateDataInYears(f_date, t_date, data, isCumulative, isInstantaneous, isDelta, true, interval);
  }
  else if (diffMonth && diffDays > 30) {
    return seggregateInMonths(f_date, t_date, data, isCumulative, isInstantaneous, isDelta, true, interval);
  }
  else if (diffDays && diffHours >= 24) {
    return seggregateInDays(f_date, t_date, data, isCumulative, isInstantaneous, isDelta, true, interval);
  }
}

const seggregateDataInYears = (from_date: string, to_date: string, data: any, isCumulative: boolean, isInstantaneous: boolean, isDelta: boolean, isSpecialCase?: boolean, interval?: string) => {
  let returnArray: any = [];
  let dateArray: any = [];
  dateArray = getDatesForYears(from_date, to_date);

  returnArray = dateArray.map((t: any) => {
    let aggregatedData: any = getDataForYears(t.from_date, t.to_date, data, isCumulative, isInstantaneous, isDelta, interval);
    data = aggregatedData.unwanted_data;
    let timestampData = seggregateInMonths(t.from_date, t.to_date, aggregatedData.data, isCumulative, isInstantaneous, isDelta, isSpecialCase, interval);
    return {
      from_date: t.from_date,
      to_date: t.to_date,
      label: aggregatedData.label,
      value: aggregatedData.value,
      data: timestampData
    }
  })
  return returnArray;
}


const seggregateInMonths = (from_date: string, to_date: string, data: any, isCumulative: boolean, isInstantaneous: boolean, isDelta: boolean, isSpecialCase?: boolean, interval?: string) => {
  let returnArray: any = [];
  let dateArray: any = [];
  dateArray = getDatesForMonths(from_date, to_date);

  returnArray = dateArray.map((t: any) => {
    let aggregatedData: any = getDataForMonths(t.from_date, t.to_date, data, isCumulative, isInstantaneous, isDelta, interval)
    data = aggregatedData.unwanted_data;
    let timestampData = seggregateInDays(t.from_date, t.to_date, aggregatedData.data, isCumulative, isInstantaneous, isDelta, isSpecialCase, interval);
    return {
      from_date: t.from_date,
      to_date: t.to_date,
      label: aggregatedData.label,
      value: aggregatedData.value,
      data: timestampData
    }
  })
  return returnArray;
}


const seggregateInDays = (from_date: string, to_date: string, data: any, isCumulative: boolean, isInstantaneous: boolean, isDelta: boolean, isSpecialCase?: boolean, interval?: string) => {
  let returnArray: any = [];
  let dateArray: any = [];
  dateArray = getDatesForDays(from_date, to_date);

  if (isSpecialCase) {
    returnArray = dateArray.map((t: any) => {
      let aggregatedData: any = getDataForDays(t.from_date, t.to_date, data, isCumulative, isInstantaneous, isDelta, interval);
      data = aggregatedData.unwanted_data;
      return {
        label: aggregatedData.label,
        value: aggregatedData.value,
      }
    })
  }
  else {
    returnArray = dateArray.map((t: any) => {
      let aggregatedData: any = getDataForDays(t.from_date, t.to_date, data, isCumulative, isInstantaneous, isDelta);
      data = aggregatedData.unwanted_data;
      let timestampData = seggregateInTimestamps(aggregatedData.data, isDelta);

      return {
        from_date: t.from_date,
        to_date: t.to_date,
        label: aggregatedData.label,
        value: aggregatedData.value,
        data: timestampData,
      }
    })
  }
  return returnArray;
}

const seggregateInTimestamps = (data: any, isDelta?: boolean) => {
  let returnArray: any = [];
  returnArray = data.map((t: any) => {
    return {
      label: moment(t.timestamp).format("DD-MM-YYYY HH:mm"),
      value: isDelta ? t.meter_usage : t.value,
      tariffDetails: t.tariffDetails,
    }
  })
  return returnArray;
}


const getDatesForDays = (from_date: string, to_date: string) => {
  let dateArray: any = [];
  let temp_date = ''
  temp_date = addDays(from_date, 1);

  dateArray.push({
    from_date,
    to_date: addDays(from_date, 1)
  })

  while (Number(moment(temp_date).format("x")) < Number(moment(to_date).format("x"))) {
    dateArray.push({
      from_date: temp_date,
      to_date: addDays(temp_date, 1)
    })
    temp_date = addDays(temp_date, 1)
  }

  return dateArray;
}

const getDataForDays = (from_date: string, to_date: string, data: any, isCumulative: boolean, isInstantaneous: boolean, isDelta: boolean, interval?: string) => {
  let returnArray: any = [];
  let realData: any = [];
  let total = 0;
  let index = 0;

  for (let i = 0; i < data.length; i++) {
    const timestamp = moment(data[i].timestamp);
    const isWithinRange = timestamp.isBetween(from_date, to_date);
    const isStartDate = timestamp.isSame(from_date);
    const isEndDate = timestamp.isSame(to_date);

    if ((interval === '1 day' && (isWithinRange || isStartDate)) ||
      (interval !== '1 day' && (isWithinRange || isStartDate || isEndDate))) {
      total += isDelta ? data[i].meter_usage : data[i].value;
      realData.push(data[i]);
      index++;
    } else {
      break;
    }
  }

  if (isCumulative && realData && realData.length > 1 && !isDelta) {
    total =  Math.max(...realData.map((d: { value: number }) => d.value));
  }

  if (isInstantaneous && realData && realData.length > 1) {
    total = Math.max(...realData.map((d: { value: number }) => d.value));
  }

  returnArray = data.slice(index);

  let label = moment(from_date).format("DD-MM-YYYY");
  return {
    label,
    value: total,
    unwanted_data: returnArray,
    data: realData,
  };
}

const getDatesForMonths = (from_date: string, to_date: string) => {
  let dateArray: any = [];
  let diffMonth = moment(to_date).diff(moment(from_date), 'months')
  let temp_date = ''

  dateArray.push({
    from_date: moment(from_date).startOf("month").format("YYYY-MM-DD"),
    to_date: addDays(moment(from_date).endOf("month").format("YYYY-MM-DD"), 1)
  })
  temp_date = moment(from_date).endOf("month").format("YYYY-MM-DD")
  temp_date = addDays(temp_date, 1);
  while (diffMonth >= 0 && moment(temp_date) < moment(to_date)) {
    dateArray.push({
      from_date: moment(temp_date).startOf("month").format("YYYY-MM-DD"),
      to_date: addDays(moment(temp_date).endOf("month").format("YYYY-MM-DD"), 1)
    })
    temp_date = moment(temp_date).endOf("month").format("YYYY-MM-DD")
    temp_date = addDays(temp_date, 1);
    diffMonth--;
  }
  return dateArray;
}

const getDataForMonths = (from_date: string, to_date: string, data: any, isCumulative: boolean, isInstantaneous: boolean, isDelta: boolean, interval?: string) => {
  let returnArray: any = [];
  let realData: any = [];
  let total = 0;
  let index = 0;

  for (let i = 0; i < data.length; i++) {
    const timestamp = moment(data[i].timestamp);
    const isWithinRange = timestamp.isBetween(from_date, to_date);
    const isStartDate = timestamp.isSame(from_date);
    const isEndDate = timestamp.isSame(to_date);

    if ((interval === '1 day' && (isWithinRange || isStartDate)) ||
      (interval !== '1 day' && (isWithinRange || isStartDate || isEndDate))) {
      total += isDelta ? data[i].meter_usage : data[i].value;
      realData.push(data[i]);
      index++;
    } else {
      break;
    }
  }

  if (isCumulative && realData && realData.length > 1 && !isDelta) {
    total = Math.max(...realData.map((d: { value: number }) => d.value));
  }

  if (isInstantaneous && realData && realData.length > 1) {
    total = Math.max(...realData.map((d: { value: number }) => d.value));
  }

  returnArray = data.slice(index);

  let label = moment(from_date).format("MMM-YY");
  return {
    label,
    value: total,
    unwanted_data: returnArray,
    data: realData,
  };
}

const getDatesForYears = (from_date: string, to_date: string) => {
  let dateArray: any = [];
  let diffMonths = moment(to_date).diff(moment(from_date), 'months');

  let temp_date = ''

  dateArray.push({
    from_date: moment(from_date).startOf("year").format("YYYY-MM-DD"),
    to_date: addDays(moment(from_date).endOf("year").format("YYYY-MM-DD"), 1)
  })
  temp_date = moment(from_date).endOf("year").format("YYYY-MM-DD")
  temp_date = addDays(temp_date, 1);
  while (diffMonths > 0 && moment(temp_date) < moment(to_date)) {
    dateArray.push({
      from_date: moment(temp_date).startOf("year").format("YYYY-MM-DD"),
      to_date: addDays(moment(temp_date).endOf("year").format("YYYY-MM-DD"), 1)
    })
    temp_date = moment(temp_date).endOf("year").format("YYYY-MM-DD")
    temp_date = addDays(temp_date, 1);
    diffMonths -= 12;
  }

  return dateArray;
}

const getDataForYears = (from_date: string, to_date: string, data: any, isCumulative: boolean, isInstantaneous: boolean, isDelta: boolean, interval?: string) => {
  let returnArray: any = [];
  let realData: any = [];
  let total = 0;
  let index = 0;

  for (let i = 0; i < data.length; i++) {
    const timestamp = moment(data[i].timestamp);
    const isWithinRange = timestamp.isBetween(from_date, to_date);
    const isStartDate = timestamp.isSame(from_date);
    const isEndDate = timestamp.isSame(to_date);

    if ((interval === '1 day' && (isWithinRange || isStartDate)) ||
      (interval !== '1 day' && (isWithinRange || isStartDate || isEndDate))) {
      total += isDelta ? data[i].meter_usage : data[i].value;
      realData.push(data[i]);
      index++;
    } else {
      break;
    }
  }

  if (isCumulative && realData && realData.length > 1 && !isDelta) {
    total = Math.max(...realData.map((d: { value: number }) => d.value));
  }

  if (isInstantaneous && realData && realData.length > 1) {
    total = Math.max(...realData.map((d: { value: number }) => d.value));
  }

  returnArray = data.slice(index);

  let label = moment(from_date).format("YYYY");

  return {
    label,
    value: total,
    unwanted_data: returnArray,
    data: realData,
  };
}

const addDays = (date: string, days: number) => {
  return moment(date, "YYYY-MM-DD").add(days, 'days').format("YYYY-MM-DD");
}

export const getUserClaim = () => {
  const decodedHeader = decodedJwt();
  let userData = JSON.parse(decodedHeader.payload.userData);
  return userData;
};


export const convertBase64ToFile = (base64String: string, fileName: string) => {
  let arr: any = base64String.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let uint8Array = new Uint8Array(n);
  while (n--) {
    uint8Array[n] = bstr.charCodeAt(n);
  }
  let file = new File([uint8Array], fileName, { type: mime });
  return file;
}

export const getChartOptionsSingleMeterPage = (isDark: boolean, isRoundOff: boolean, changeGraph: any, changeGraphOnZoom: any, meterEvents: MeterEventData[], interval: string) => {
  return {
    onClick: (event: any, element: any) => {
      if (element.length > 0) {
        changeGraph(element[0].index);
      }
    },
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontFamily: 'Nunito Sans, sans-sarif',
        fontColor: '#8898aa',
      },
    },
    interaction: {
      intersect: false
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl',
        },
        zoom: {
          wheel: { enabled: true, modifierKey: 'alt' },
          drag: { enabled: true },
          pinch: { enabled: true },
          mode: 'x',
          onZoom: ({ chart }: any) => {
            const { chartArea } = chart;
            if (chartArea) {
              const { left, right } = chartArea;
              const labels = chart.data.labels;
              const startIndex = chart.scales.x.getValueForPixel(left);
              const endIndex = chart.scales.x.getValueForPixel(right);
              if (moment(labels[startIndex], DATE_TIME_FORMAT.DATE, true).isValid()) {
                const selectedLabels = labels.slice(startIndex, endIndex);
                if (selectedLabels && selectedLabels.length <= 7)
                  changeGraphOnZoom(startIndex, endIndex);
              }
            }
          },
          onZoomComplete: ({ chart }: any) => {
            chart.update();
          }
        }

      } as ZoomPluginOptions,
      tooltip: {
        caretPadding: 25,
        padding: 15,
        yAlign: "bottom",
        xAlign: "right",
        x: "",
        y: "",
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }

            if (context.parsed.y !== null) {
              label += getRoundOffValues(context.parsed.y, isRoundOff ? null : 10);
            }

            return label;
          },
          afterLabel: function (context: any) {
            let label = '';
            let thresholdMinutes = Math.abs(intervalToMin(interval) / 2);
            if (meterEvents && meterEvents.length) {
              const eventTimestamps = meterEvents.map((obj) => obj.event_timestamp);
              let isEventOccur = false;
              let title = "";
              meterEvents.map((item) => {
                let eventtimeStamp = moment.utc(item.event_timestamp).format('DD-MM-YYYY HH:mm');
                if (isNearbyDate(context.label, eventtimeStamp, thresholdMinutes)) {
                  isEventOccur = isNearbyDate(context.label, eventtimeStamp, thresholdMinutes);
                  title = item.title;
                }
              })
              if (isEventOccur) {
                label += title;
                return label;
              }


            }

            return '';
          },
        },
      },

      crosshair: {
        line: {
          color: isDark ? "#fff" : '#000',
          width: 0.5,
          dashPattern: [5, 3],
        },
        sync: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: isDark ? 'rgba(255,255,255,0.5)' : '#8898aa',
        },
      },
      x: {
        ticks: {
          color: isDark ? 'rgba(255,255,255,0.5)' : '#8898aa',
        }
      }
    }
  }
}

export const getValidMomentInExport = (value: any) => {
  return value ? (moment.isMoment(value) ? moment(value).format(DATE_TIME_FORMAT.DATE) : value) : "";
}

export const scrollTop = () => {
  const scrollableDivElement = document.getElementById('mainContainer');
  if (scrollableDivElement) {
    scrollableDivElement.scrollTop = 0;
  }
}

export const getColumnWidthData = () => {
  const columnWidthData = localStorage.getItem('columnWidthData');
  return columnWidthData ? JSON.parse(columnWidthData) : {};
};

export const setColumnWidthData = (pageTableKey: string, columnWidths: any) => {
  const columnWidthData = getColumnWidthData();
  columnWidthData[pageTableKey] = columnWidths;
  localStorage.setItem('columnWidthData', JSON.stringify(columnWidthData));
};

export const validatePhoneNumber = (phone_number: string) => {
  if (PHONENUMBER_REGEX.test(phone_number)) {
    return isValidPhoneNumber(phone_number, 'AU');
  }
  return false;
}
export const generateRequestKey = (config: any) => {
  const method = config?.method ?? 'unknown';
  const url = config?.url ?? 'unknown';
  return `${method}-${url}`;
};


export const disableInvalidToDate = (current: Moment, fromdate: string, flag?: boolean, maxdate?: string) => {
  const validateDate = (value: string, format: string) => {
    return moment(value, format, true).isValid();
  };
  if ((fromdate && !validateDate(fromdate, DATE_TIME_FORMAT.DATE) && !validateDate(fromdate, DATE_TIME_FORMAT.DATE_TIME_DATEPICKER))) {
    return current.isBefore(moment())
  }
  let dateObject = new Date();
  if (!fromdate.includes(':')) {
    const [day, month, year] = fromdate.split('-').map(Number);
    dateObject = new Date(year, month - 1, day);
  }
  else {
    dateObject = new Date(fromdate);
  }

  if (maxdate?.length) {
    const maxdates = moment().subtract(Number(maxdate), 'days');
    if (flag) {
      return !fromdate || current.isSameOrAfter(dateObject);
    } else {
      return !fromdate ?
        (current.isBefore(moment()) && (maxdate?.length ? current.isAfter(maxdates!) : false)) :
        (current.isSame(dateObject, 'day') || (current.isSameOrAfter(dateObject) && current.isBefore(moment()) && (maxdate?.length ? current.isAfter(maxdates!) : false)));
    }
  }
  else {
    if (flag) {
      return !fromdate || current.isSameOrAfter(dateObject);
    } else {
      return !fromdate ? current.isBefore(moment()) : (current.isSame(dateObject, 'day') || (current.isSameOrAfter(dateObject) && current.isBefore(moment())));
    }
  }

};
export const disableInvalidFromDate = (current: Moment, todate: string, flag?: boolean, maxdate?: string) => {
  const validateDate = (value: string, format: string) => {
    return moment(value, format, true).isValid();
  };
  if ((todate && !validateDate(todate, DATE_TIME_FORMAT.DATE) && !validateDate(todate, DATE_TIME_FORMAT.DATE_TIME_DATEPICKER))) {
    return current.isBefore(moment())
  }
  let dateObject = new Date();
  if (!todate.includes(':')) {
    const [day, month, year] = todate.split('-').map(Number);
    dateObject = new Date(year, month - 1, day);
  }
  else {
    dateObject = new Date(todate);
  }



  if (maxdate?.length) {
    const maxdates = moment().subtract(Number(maxdate), 'days');
    if (flag) {
      return !todate || current.isSameOrBefore(dateObject);
    } else {
      return !todate ?
        (current.isBefore(moment()) && (maxdate?.length ? current.isAfter(maxdates!) : false)) :
        (current.isSameOrBefore(dateObject) && current.isBefore(moment()) && (maxdate?.length ? current.isAfter(maxdates!) : false));
    }
  }
  else {
    if (flag) {
      return !todate || current.isSameOrBefore(dateObject);
    } else {
      return !todate ?
        (current.isBefore(moment())) :
        (current.isSameOrBefore(dateObject) && current.isBefore(moment()));
    }
  }
}

export const disableDataSubstitutionTypes = (option: ISelectOption) => {
  return !ENABLE_DATA_SUBSTITUTION_TYPE.includes(option.value);
}

export const dateValidation = (from_date: string, to_date: string, required_flag?: boolean, time_flag?: boolean) => {
  const currentDate = moment();
  let formIsValid = true;
  const error_object = {
    from_date: '',
    to_date: '',
    formIsValid: true,
  };

  const validateDate = (value: string, format: string) => {
    return moment(value, format, true).isValid();
  };
  const validateTime = (value: string, format: string) => {
    const momentObject = moment(value, format);
    const isMidnight = momentObject.isSame(momentObject.clone().startOf('day'));
    return isMidnight;
  }
  if (!from_date && !required_flag) {
    formIsValid = false;
    error_object.from_date = 'From date is required';
  } else if (from_date && !validateDate(from_date, DATE_TIME_FORMAT.DATE) && !validateDate(from_date, DATE_TIME_FORMAT.DATE_TIME_DATEPICKER)) {
    formIsValid = false;
    error_object.from_date = 'Please enter a valid format';
  } else if (from_date && !moment(from_date, DATE_TIME_FORMAT.DATE_TIME_DATEPICKER, true).isSameOrBefore(currentDate) && !moment(from_date, DATE_TIME_FORMAT.DATE, true).isSameOrBefore(currentDate)) {
    formIsValid = false;
    error_object.from_date = 'From date should not be in the future';
  } else if (time_flag && from_date && !(validateTime(from_date, DATE_TIME_FORMAT.DATE) || validateTime(from_date, DATE_TIME_FORMAT.DATE_TIME_DATEPICKER))) {
    formIsValid = false;
    error_object.from_date = 'Report from date should start from  mid-night';
  }

  if (!to_date && !required_flag) {
    formIsValid = false;
    error_object.to_date = 'To date is required';
  } else if (to_date && !validateDate(to_date, DATE_TIME_FORMAT.DATE) && !validateDate(to_date, DATE_TIME_FORMAT.DATE_TIME_DATEPICKER)) {
    formIsValid = false;
    error_object.to_date = 'Please enter a valid format';
  } else if (to_date && !moment(to_date, DATE_TIME_FORMAT.DATE_TIME_DATEPICKER, true).isSameOrBefore(currentDate) && !moment(to_date, DATE_TIME_FORMAT.DATE, true).isSameOrBefore(currentDate)) {
    formIsValid = false;
    error_object.to_date = 'To date should not be in the future';
  } else if (time_flag && to_date && !(validateTime(to_date, DATE_TIME_FORMAT.DATE) || validateTime(to_date, DATE_TIME_FORMAT.DATE_TIME_DATEPICKER))) {
    formIsValid = false;
    error_object.to_date = 'Report to date should start from  mid-night';
  }


  let dateobject = prepareRequestDateObject(from_date, to_date);
  if (formIsValid && from_date && to_date && dateobject.from_date == dateobject.to_date) {
    formIsValid = false;
    error_object.to_date = 'From date and to date should not be identical';
  }
  if (formIsValid && from_date && to_date && dateobject.from_date > dateobject.to_date) {
    formIsValid = false;
    error_object.to_date = 'To Date Cannot Be Before From Date';
  }


  error_object.formIsValid = formIsValid;

  return error_object;
};

export const prepareRequestDateObject = (from_date: string, to_date: string) => {
  let todateObject = new Date();
  if (!to_date.includes(':')) {
    const [day, month, year] = to_date.split('-').map(Number);
    todateObject = new Date(year, month - 1, day);
  }
  else {
    todateObject = new Date(to_date);
  }
  let fromdateObject = new Date();
  if (!from_date.includes(':')) {
    const [day, month, year] = from_date.split('-').map(Number);
    fromdateObject = new Date(year, month - 1, day);
  }
  else {
    fromdateObject = new Date(from_date);
  }
  let object = {
    from_date: from_date ? moment(fromdateObject).format(DATE_TIME_FORMAT.REQUEST_DATE_TIME) : '',
    to_date: to_date ? moment(todateObject).format(DATE_TIME_FORMAT.REQUEST_DATE_TIME) : '',
  };

  return object;
}

export const isNearbyDate = (givenDate: string, timestamp: string, thresholdMinutes: number): boolean => {
  const givenMoment = moment(givenDate, ['MMM-DD', 'DD-MM-YYYY', 'DD-MM-YYYY HH:mm']);
  const timestampMoment = moment(timestamp, DATE_TIME_FORMAT.DATE_TIME);
  if (givenMoment.format('MMM-YY') === givenDate) {
    return givenMoment.format('MMM-YY') === timestampMoment.format('MMM-YY');
  }
  else if (givenMoment.format('DD-MM-YYYY') === givenDate) {
    return givenMoment.isSame(timestampMoment, 'day');
  }
  else {
    const timeDifference = Math.abs(givenMoment.diff(timestampMoment, 'minutes'));
    return timeDifference <= thresholdMinutes;
  }
};

export const intervalToMin = (interval: string) => {
  if (interval === "1 min") {
    return 1
  }
  else if (interval === "5 min") {
    return 5
  }
  else if (interval === "10 min") {
    return 10
  }
  else if (interval === "15 min") {
    return 15
  }
  else if (interval === "30 min") {
    return 30
  }
  else if (interval === "1 hour") {
    return 60
  } else if (interval === "6 hour") {
    return 360
  } else {
    return 15
  }
}


export const SystemReadingZone = (zone: string) => {
  moment.tz.setDefault(zone);
}


export const checkValidDate = (current: Moment, fromdate: string) => {
  const validateDate = (value: string, format: string) => {
    return moment(value, format, true).isValid();
  };
  if ((fromdate && !validateDate(fromdate, DATE_TIME_FORMAT.DATE) && !validateDate(fromdate, DATE_TIME_FORMAT.DATE_TIME_DATEPICKER))) {
    return current.isSameOrAfter(moment())
  }
  let dateObject = new Date();
  if (!fromdate.includes(':')) {
    const [day, month, year] = fromdate.split('-').map(Number);
    dateObject = new Date(year, month - 1, day);
  }
  else {
    dateObject = new Date(fromdate);
  }
  return current.isSameOrAfter(moment());
};